import React from 'react';
import { DateTime } from '@vgn-medien-holding/vgn-react-components';
import { twMerge } from 'tailwind-merge';
import { Link } from '@components/atoms/Link/Link';
import { createDate } from '@utils/dateHelpers';
import { CinemaShowtime as CinemaShowtimeScheme } from '@lib/streaming';
import { IconBall } from '@assets/icon-ball';

export interface CinemaShowtimeLocationProps {
  showtimes: CinemaShowtimeScheme[];
}

export const CinemaShowtimeLocation = ({ showtimes }: CinemaShowtimeLocationProps) => {
  return (
    <div
      key={showtimes?.[0]?.cinema?.name}
      className="my-auto grid gap-3 border-y border-gray-650/25 bg-gray-820/70 px-8 py-3 transition-colors duration-300 hover:border-y-0.5 sm:gap-10 md:grid-cols-[1fr,auto] md:px-16 pointer:hover:border-gray-400"
    >
      <div className="grid w-48 content-start gap-1 2xl:w-64">
        <div className="hyphenate mt-1 font-herokid text-base font-bold">{showtimes?.[0]?.cinema?.name}</div>
        <div className="hyphenate text-2xs font-bold uppercase leading-small tracking-2px text-gray-500 sm:leading-body">
          {showtimes?.[0]?.cinema?.city?.name}
        </div>
      </div>
      <div className="flex justify-between gap-2 md:justify-end lg:gap-6">
        <div className="flex flex-wrap content-center items-center gap-y-1 divide-x divide-solid divide-gray-650/75 md:justify-end">
          {showtimes?.map((s) => (
            <div
              className="grid w-20 flex-none justify-center px-4 text-base font-bold tracking-2px md:text-lg"
              key={s.id + 'time'}
            >
              <Link href={s.booking_link} noLink={!s.booking_link} target="_blank" rel="noopener noreferrer">
                <DateTime date={createDate(s?.start)} formatOptions={{ timeStyle: 'short' }} />
              </Link>
            </div>
          ))}
        </div>
        <div className="grid items-center justify-end">
          <Link
            href={showtimes?.[0]?.cinema?.url}
            noLink={!showtimes?.[0]?.cinema?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className={twMerge(
                'inline-grid h-10 w-10 items-center justify-center rounded-full border border-gray-650/65 bg-gray-650/25 opacity-30',
                showtimes?.[0]?.cinema?.url && 'opacity-100',
              )}
            >
              <IconBall classProps={{ root: 'w-5 h-5 ml-auto' }} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
