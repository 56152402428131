import React, { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

const Header = dynamic(() => import('@components/organisms/Header/Header').then((module) => module.Header));
const Footer = dynamic(() => import('@components/organisms/Footer/Footer').then((module) => module.Footer));

export const Default = ({ children }: PropsWithChildren) => {
  return (
    <>
      <div className="dark relative h-screen w-full bg-black text-white">
        <Header classProps={{ root: 'relative' }} filled={false} />

        {/* INFO: -mt-px pt-px solves the issue with margin-top - please do not remove. :) */}
        <main className="-mt-px pt-px">
          <div className="w-full px-4 pt-8 sm:px-8 md:px-16 md:pt-16 lg:mt-24">{children}</div>
        </main>
        <Footer />
      </div>
    </>
  );
};
