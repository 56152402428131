import React, { useEffect, useState } from 'react';
import { CinemaShowtimeLocation } from '@components/molecules/CinemaShowtimeLocation/CinemaShowtimeLocation';
import { GetMovieShowtimesUpcomingQuery } from '@lib/graphql/generated';

export interface CinemaShowtimesProps {
  showtimes: GetMovieShowtimesUpcomingQuery['movie']['showtimesUpcoming']['data'];
  cinema?: string;
  state?: string;
  noFilter?: boolean;
}

export const CinemaShowtimes = ({ showtimes, cinema, state, noFilter }: CinemaShowtimesProps) => {
  const [filteredShowtimes, setFilteredShowtimes] = useState({ data: [] });

  useEffect(() => {
    setFilteredShowtimes(() => {
      const data = noFilter
        ? showtimes
        : showtimes?.filter((item) => {
            let shouldReturn = true;

            if (state && item.cinema.city.state_code != state) {
              shouldReturn = false;
            }

            if (cinema && item.cinema.id != cinema) {
              shouldReturn = false;
            }

            if (shouldReturn === true) {
              return item;
            }
          });

      const groupedData = data.reduce(
        (hash, obj) => ({ ...hash, [obj.cinema.id]: (hash[obj.cinema.id] || []).concat(obj) }),
        {},
      );
      return { data: Object.values(groupedData) };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showtimes]);

  return (
    <>
      {filteredShowtimes?.data?.length > 0 && (
        <div className="grid gap-y-2">
          {filteredShowtimes.data.map((showtimesLocation, index) => (
            <CinemaShowtimeLocation key={index} showtimes={showtimesLocation} />
          ))}
        </div>
      )}
    </>
  );
};
